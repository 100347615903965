








































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class DownloadAssets extends Vue {
    @Prop(Boolean)
    private table!: boolean
    @Prop(Array)
    private assets!: Release.Asset[]
}
