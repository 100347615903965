















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
// tslint:disable-next-line:no-var-requires
const customProtocolDetection = require('custom-protocol-detection')
import DownloadAssets from './DownloadAssets.vue'

@Component({
    components: {
        DownloadAssets
    }
})
export default class Bootstrap extends Vue {
    private openFailed = false
    private showDownloads = false

    get preferredAsset() { return this.$env.preferredAsset(this.$env.syncReleases[0].assets) }
    private created() {
        this.$ga.page('/connex-env/bootstrap')
    }

    private mounted() {
        setTimeout(() => this.open(), 100)
    }
    private open() {
        const fallback = () => {
            this.openFailed = true
            this.showDownloads = true
        }

        // tslint:disable-next-line:max-line-length
        const vechainAppUrl = `vechain-app://${encodeURIComponent(this.$env.target.network)}/${encodeURIComponent(this.$env.target.href)}`
        customProtocolDetection(vechainAppUrl,
            fallback,
            // tslint:disable-next-line:no-console
            () => console.log('opened with sync'),
            fallback)
    }
}
