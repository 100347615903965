












































import { Vue, Component } from 'vue-property-decorator'
import SyncPage from './SyncPage.vue'
import ThorWalletPage from './ThorWalletPage.vue'

@Component({
    components: {
        SyncPage,
        ThorWalletPage
    }
})
export default class Home extends Vue {
    private hash = location.hash

    private created() {
        this.$ga.page('/connex-env/home')
    }

    private switchTab(hash: string) {
        location.href = hash
        this.hash = hash
    }
}
