































































import { Vue, Component } from 'vue-property-decorator'
import DownloadAssets from './DownloadAssets.vue'

@Component({
    components: {
        DownloadAssets
    }
})
export default class SyncPage extends Vue {
    get preferredAsset() { return this.$env.preferredAsset(this.$env.syncReleases[0].assets) }
}
